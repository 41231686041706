<template>
    <div class="select">
        <div class="select-input-wrapper">
            <input 
                readonly="true" 
                class="select-input" 
                ref="inputRef" />
            <div v-if="selectedValue" 
                class="select-div label__medium" 
                @click="focus">
                    <div class="account-symbol symbol" 
                        :class="`symbol-${selectedValue.currency}`"></div>
                    <span>{{ ((selectedValue.alias && selectedValue.alias.toUpperCase()) || selectedValue.account_name || selectedValue.group.toUpperCase() ) + ' Account' }}</span>
            </div>
            <i class="material-symbols-outlined">arrow_drop_down</i>
            <div class="empty-item"></div>
            <ul class="select-list" 
                :style="{ 'max-height': `${(6 * 36) + 8}px` }">
                    <li v-for="(account, index) in accounts" 
                        :key="index" 
                        class="label__medium" 
                        :class="{ active: account.id === selectedValue.id }"
                        @mousedown="setOption(account)">
                            <div class="account-symbol symbol" 
                                :class="`symbol-${account.currency.toUpperCase()}`"></div>
                            <div class="account-info">
                                <span>{{ (account.alias.toUpperCase() || account.account_name || account.group.toUpperCase()) + ' Account' }}</span>
                                <span class="body__extrasmall">{{ account.product.toUpperCase() + ' #' + account.id }}</span>
                            </div>
                    </li>
            </ul>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    name: "BaseAccountSelect",
    props: {
        selectedAccount: Object
    },
    data() {
        return {
            selectedValue: this.selectedAccount
        }
    },
    computed: {
        ...mapGetters(['accounts', 'tokens'])
    },
    methods: {
        setOption(account) {
            this.selectedValue = account
            this.$emit('update', this.selectedValue)
        },
        focus() {
            this.$refs.inputRef.focus()
        }
    },
    watch: {
        selectedAccount: {
            deep: true,
            handler(val) {
                this.selectedValue = val
            }
        }
    }
}
</script>

<style scoped>
.select,
.select-input-wrapper {
    position: relative;
}

.select i {
    position: absolute;
    bottom: 9px;
    right: 5px;
    z-index: 1;
    transition: transform .3s ease;
    color: var(--System-SecondaryFixed);
}

.select-input:focus~i {
    transform: rotate(180deg)
}

.select-input {
    padding: 8px 30px 8px 8px;
    min-height: 38px;
    border-radius: 4px;
    background: transparent;
    border: none;
    background: var(--Statelayers-primary-opacity12);
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
    cursor: pointer;
}

.select-input::placeholder {
    color: var(--System-Outline);
}

.select-input:focus {
    border-color: var(--System-Primary);
}

.select-list {
    display: none;
    position: absolute;
    top: 44px;
    width: 100%;
    min-width: 200px;
    overflow-x: auto;
    color: var(--System-OnSurface);
    padding: 8px 0;
    border-radius: 4px;
    background: var(--System-Surface3);
    flex-direction: column;
    z-index: 20;
}

.empty-item {
    display: none;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 2;
}

.select-input:focus~.empty-item,
.select-input:focus~.select-list {
    display: flex;
}

.select-list li {
    padding: 8px 10px;
    cursor: pointer;
    min-height: 36px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.select-list li.active,
.select-list li:hover {
    background: var(--Statelayers-primary-opacity16);
}

.account-symbol {
    zoom: .25
}

.account-info {
    display: flex;
    flex-direction: column;
    color: var(--System-OnSecondaryContainer);
    align-items: flex-start;
    justify-content: center;
}

.account-info span:last-child {
    color: var(--System-Secondary);
}

.select-div {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    height: 100%;
    padding: 0 8px;
    cursor: pointer;
    color: var(--System-OnSecondaryContainer);
}
</style>