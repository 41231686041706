<template>
    <div v-ripple class="news">
        <a :href="`https://${getSource(news.url)}`">
            <div class="header">
                <div class="left">
                    <div class="img">
                        <img :src="news.img"/>
                    </div>
                    <div class="news-info">
                        <div class="time">{{ timeAgo(news.time * 1000) }}</div>
                        <a class="source" :href="getSource(news.url)">{{getSource(news.url)}}</a>
                    </div>
                </div>
                <a :href="news.url" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z" fill="#3F454F"/>
                    </svg>
                </a>
            </div>
            <div class="body">
                <h4 class="body__medium title">{{ news.title }}</h4>
            </div>
            <div class="summary" v-html="news.summary"></div>
        </a>
    </div>
</template>

<script>
export default {
    name: "NewsListItem",
    props: {
        news: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        getSource(url) {
            return (new URL(url)).hostname
        },
        timeAgo(time) {
            const now = new Date()
            const seconds = Math.floor((now - new Date(time)) / 1000)

            let interval = Math.floor(seconds / 31536000)
            if (interval >= 1) return interval + " year" + (interval > 1 ? "s" : "") + " ago"

            interval = Math.floor(seconds / 2592000)
            if (interval >= 1) return interval + " month" + (interval > 1 ? "s" : "") + " ago"

            interval = Math.floor(seconds / 86400)
            if (interval >= 1) return interval + " day" + (interval > 1 ? "s" : "") + " ago"

            interval = Math.floor(seconds / 3600)
            if (interval >= 1) return interval + " hour" + (interval > 1 ? "s" : "") + " ago"

            interval = Math.floor(seconds / 60)
            if (interval >= 1) return interval + " minute" + (interval > 1 ? "s" : "") + " ago"

            return "Just now"
        }
    }
}
</script>

<style scoped>
.news {
    padding: 16px 12px;
}

.news:hover {
    background: var(--Statelayers-onsurface-opacity08);
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    height: 30px;
}

.body {
    margin-bottom: 4px;
}

.left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.news-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.img {
    border-radius: 2px;
    overflow: hidden;
}

img {
    width: 52px;
    height: 30px;
    object-fit: cover;
    float: left;
}

.title {
    color: var(--System-OnSurface);
}

.summary {
    font-size: 11px;
    font-weight: 300;
    color: var(--System-OnSurfaceVariant);
}

.source {
    font-size: 11px;
    font-weight: 400;
    color: var(--System-Outline);
}

.time {
    font-size: 10px;
    font-weight: 400;
    color: var(--System-Outline);
}

a {
    text-decoration: none;
}
</style>
