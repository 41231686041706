<template>
    <div class="acc-row-container">
        <div class="acc-row real label__medium" 
            :class="{
                        active: activeAccount.id === account.id,
                        real: !account.demo,
                        demo: account.demo,
                        'disable-bottom-border-radius' : activeAccount.id === account.id,
                    }" >
            <div class="row">
                <div class="symbol" :class="`symbol-${account.currency}`"></div>
                <div class="col">
                    <span>
                        {{ (account.alias && account.alias.toUpperCase()) ||
                            (account.account_name && account.account_name != 'MAIN' ? 
                                account.account_name : account.group === 'main' ? 
                                    'STANDARD' : account.group.toUpperCase()) }} 
                        {{ tokens.balanceModal.account }} 
                    </span>
                    <span>CFD #{{ account.id }}</span>
                </div>
                <div class="balance">{{ currencySign(account.currency) }}{{
                        account.balance.toLocaleString(undefined, {
                            minimumFractionDigits: account.precision,
                            maximumFractionDigits: account.precision
                        }) }}</div>
            </div>
            <BaseRadioButton :state="activeAccount.id === account.id" :hoverDisable="true"/>
        </div>
        <AccountListItemInfo v-if="account.id === activeAccount.id"  />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {currencySign} from "@/common/helpers";
import BaseRadioButton from "../../../shared/ui/Inputs/BaseRadioButton.vue";
import AccountListItemInfo from "@/new-design/not-sorted/new/Modals/AccountListItemInfo.vue";


export default {
    name: 'AccountListItem',
    components: {BaseRadioButton,AccountListItemInfo},
    props: {
        account: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        ...mapGetters(['tokens','activeAccount']),
    },
    methods: {currencySign}
}

</script>

<style scoped>
.acc-row-container {
    margin-bottom: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
}

.acc-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    transition: background-color ease-in-out 0.3s;
    border-radius: 8px;
    background-color: var(--System-Surface4);
    color: var(--System-OnSurface);
    gap: 15px;
}

.acc-row:hover,
.acc-row.active {
    background-color: #394253;
}

.acc-row .col {
    font-size: 12px;
    margin-left: 16px;
}

.acc-row .col span:last-child {
    margin-top: 2px;
    color: var(--System-OnSurfaceVariant)
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
}

.acc-row .balance {
    font-size: 16px;
    margin-left: auto;
}

.demo .balance {
    color: var(--brand)
}

.acc-row .symbol {
    zoom:  .3;
}

.acc-row .button {
    color: var(--white-to-black);
    font-size: 1.1em;
    font-weight: 400;
    width: 120px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background);
    border-radius: 2px;
}

.disable-bottom-border-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@media (max-width: 767px) {
    .acc-row-container {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>