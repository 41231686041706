
<template>
    <div class="old-design" :class="{mobile: isMobile}" :style="lightTheme ? cssVarsLight : cssVars" >
        <div class="blur-container" :class="{blur: blur}">
            <marquee class="alert-message-marquee" v-if="!modals.fullscreen && alertMessage">
                {{ alertMessage }}
            </marquee>
            <Header/>
            <div class="main">
                <SideMenu/>
                <Menu :class="{hide: !isMobile}"/>
                <MenuModal/>
                <div class="bigPane">
                    <Grid/>
                    <OrdersPane/>
                    <StatusBar/>
                </div>
            </div>
            <Footer/>
        </div>
        <LoginModal v-if="!['tradingviewonline'].includes(brand)"/>
        <CustomLoginModals v-else/>
        <NoLandscape/>
        <ProfitCalculatorModal/>
        <AssetsInfoModal/>
        <RegisterModal/>
        <AccountModal/>
        <SymbolsModal/>
        <BalanceModal/>
        <AccountModal/>
        <GridsModal/>
        <NoConnectionModal/>
        <AutoCloseModal/>
        <PendingModal/>
        <Notifications/>
        <ForgotPasswordModal/>
        <NewPasswordModal/>
        <ClosePositionModal/>
        <DepositSuccessModal/>
        <DepositFailModal/>
        <WithdrawalCancelModal/>
        <Fullscreen/>
        <AutoTraderModal/>
        <PciDssModal />
        <QrCodeModal />
        <TimePickerModal/>
        <AlertMessageModal v-if="alertMessage" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {isMobile} from '@/common/helpers'

import Header from "@/components/Header"
import Footer from "@/components/Footer"
import SideMenu from "@/components/SideMenu"
import NoLandscape from "@/components/Modals/NoLandscape"
import SymbolsModal from "@/components/Modals/SymbolsModal"
import ProfitCalculatorModal from "@/components/Modals/ProfitCalculatorModal"
import AssetsInfoModal from "@/components/Modals/AssetsInfoModal"
import BalanceModal from "@/components/Modals/BalanceModal"
import LoginModal from "@/components/Modals/LoginModal"
import CustomLoginModals from "@/components/CustomLoginModals"
import GridsModal from "@/components/Modals/GridsModal"
import AccountModal from "@/components/Modals/AccountModal"
import Notifications from "@/components/Notifications"
import RegisterModal from "@/components/Modals/RegisterModal"
import NoConnectionModal from '@/components/Modals/NoConnectionModal'
import MenuModal from '@/components/Modals/MenuModal'
import Menu from '@/components/Modals/HamburgerMenu'
import AutoCloseModal from '@/components/Modals/AutoCloseModal'
import PendingModal from '@/components/Modals/PendingModal'
import ForgotPasswordModal from '@/components/Modals/ForgotPasswordModal'
import NewPasswordModal from '@/components/Modals/NewPasswordModal'
import ClosePositionModal from '@/components/Modals/ClosePositionModal'
import DepositSuccessModal from '@/components/Modals/DepositSuccessModal'
import DepositFailModal from '@/components/Modals/DepositFailModal'
import WithdrawalCancelModal from '@/components/Modals/WithdrawalCancelModal'
import PciDssModal from "@/components/Modals/PciDssModal"
import QrCodeModal from "@/components/Modals/QrCodeModal"
import AlertMessageModal from "@/components/Modals/AlertMessageModal"
import Grid from '@/components/Grid'
import OrdersPane from "@/components/OrdersPane"
import StatusBar from "@/components/StatusBar"
import Fullscreen from "@/components/Modals/Fullscreen"

import {
    BRAND,
    COLOR_FONT,
    COLOR_BG_FOOTER,
    COLOR_BG_DARK,
    COLOR_BG_DARK_MID,
    COLOR_BG,
    COLOR_BG_LIGHT,
    COLOR_WHITE,
    COLOR_BRAND,
    COLOR_GREEN,
    COLOR_GREEN_BUTTON,
    COLOR_RED,
    COLOR_PRIMARY_BUTTON,
    COLOR_SECONDARY_BUTTON,
    COLOR_BORDER,
    COLOR_MARKET_WATCH_TITLE,
    COLOR_BG_ASSETS_INFO,
    COLOR_BG_LOGIN,
    COLOR_BG_LOGIN_BTN,
    CUSTOM_LOGIN_MODAL
} from "@/common/config"
import AutoTraderModal from "@/components/Modals/AutoTraderModal"
import TimePickerModal from "@/components/Modals/TimePickerModal";
// import TimePickerModal from "@/components/Modals/TimePickerModal";

export default {
    name: 'OldDesign',
    mounted() {
        this.updateStyles()
    },
    components: {
        TimePickerModal,
        AutoTraderModal,
        StatusBar,
        OrdersPane,
        Grid,
        PendingModal,
        AutoCloseModal,
        MenuModal,
        Menu,
        AssetsInfoModal,
        NoConnectionModal,
        NoLandscape,
        ProfitCalculatorModal,
        RegisterModal,
        Notifications,
        AccountModal,
        GridsModal,
        LoginModal,
        CustomLoginModals,
        BalanceModal,
        ForgotPasswordModal,
        NewPasswordModal,
        ClosePositionModal,
        DepositSuccessModal,
        DepositFailModal,
        WithdrawalCancelModal,
        SymbolsModal, SideMenu, Footer, Header,
        Fullscreen,
        PciDssModal,
        QrCodeModal,
        AlertMessageModal
    },
    data() {
        return {
            newPlatformDesign: false,
            brand: BRAND,
            customLoginModal: CUSTOM_LOGIN_MODAL,
            sessionStateInit: false,
            isMobile: isMobile(),
            cssVars: {
                '--font': COLOR_FONT,
                '--background-footer': COLOR_BG_FOOTER,
                '--background-dark': COLOR_BG_DARK,
                '--background-dark-mid': COLOR_BG_DARK_MID,
                '--background': COLOR_BG,
                '--background-light': COLOR_BG_LIGHT,
                '--white': COLOR_WHITE,
                '--white-to-black': COLOR_WHITE,
                '--brand': COLOR_BRAND,
                '--green': COLOR_GREEN,
                '--green-button': COLOR_GREEN_BUTTON,
                '--red': COLOR_RED,
                '--primary-button': COLOR_PRIMARY_BUTTON,
                '--secondary-button': COLOR_SECONDARY_BUTTON,
                '--border': COLOR_BORDER,
                '--market-watch-title': COLOR_MARKET_WATCH_TITLE,
                '--assets-info-gradient': COLOR_BG_ASSETS_INFO,
                '--color-bg-login': COLOR_BG_LOGIN,
                '--color-bg-login-btn': COLOR_BG_LOGIN_BTN,

                '--aspin-background-light': COLOR_BG_LIGHT,
            },
            cssVarsLight: {
                '--font': '#313131',
                '--background-footer': '#fff',
                '--background-dark': '#fff',
                '--background-dark-mid': '#fbfbfb',
                '--background': '#fbfbfb',
                '--brand': COLOR_BRAND,
                '--green-button': COLOR_GREEN_BUTTON,
                '--border': '#E8F2F9',
                '--white-to-black': '#000',
                '--assets-info-gradient': 'linear-gradient(0deg, rgba(245, 255, 245, 1) 0%, rgba(245, 255, 245, .7) 45%, rgba(24, 31, 45, 0.45) 100%)',

                '--aspin-background-light': '#fbfbfb'

            },
        }
    },
    methods: {
        updateStyles() {
            const oldDesignLink = document.querySelector('link.old-design-style')
            const newDesignLink = document.querySelector('link.new-design-style')

            if (newDesignLink) {
                newDesignLink.remove()
            }
            if (!oldDesignLink) {
                this.addStyleSheet('../designs/old-design/general.css', 'old-design-style')
            }
        },
        addStyleSheet(href, className) {
            const linkElement = document.createElement('link')
            linkElement.rel = 'stylesheet'
            linkElement.href = href
            linkElement.className = className

            document.head.appendChild(linkElement)
        },
    },
    computed: {
        ...mapGetters(['blur', 'symbols', 'sessionsState','staticBase','lightTheme','variables','modals']),
        alertMessage(){
            return this.variables.ALERT_MESSAGE ? this.variables.ALERT_MESSAGE.trim() : false
        }
    },
}
</script>

<style>

</style>

